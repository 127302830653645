/* ========= CSS Variables ========= */
:root {
  --color-primary: #da4527;
  --color-secondary: #195083;
  --color-cream: #faf0ca;
  --color-black: #000000;
  --color-white: #ffffff;

  --font-stack: "Helvetica Neue", HelveticaNeue, "TeX Gyre Heros", TeXGyreHeros,
    FreeSans, "Nimbus Sans L", "Liberation Sans", Arimo, Helvetica, Arial,
    sans-serif;

  --border-standard: 4px solid var(--color-black);
}

/* ========= Base Styles ========= */
.scrollbar::-webkit-scrollbar {
  display: none;
}

/* ========= Typography ========= */
.regularfont {
  font-family: var(--font-stack);
  font-weight: bold;
  font-size: 120%;
}

.thinfont {
  font-family: var(--font-stack);
  font-weight: lighter;
  font-size: 100%;
}

.subthinfont {
  font-family: var(--font-stack);
  font-weight: lighter;
}

.subheaderfont {
  font-family: var(--font-stack);
  font-weight: bold;
  font-size: 16px;
}

.regularfontguest {
  color: #faf0ca;
  font-family: var(--font-stack);
  font-weight: bold;
  font-size: 700%;
  letter-spacing: -0.12em;
}

.smaller-font {
  font-size: 90%; /* Adjust this value as needed */
}

/* ========= Header Section ========= */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-black);
  width: 100%;
  height: 30em;
  overflow: hidden;
  position: relative;
}

.videocontainer {
  margin-top: 0; /* Remove any top margin */
  padding-top: 0; /* Remove any top padding */
  width: 100%;
  height: 45em;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center; /* Horizontally centers content */
  align-items: center; /* Vertically centers content */
}

.videocontainer video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 115%; /* Ensures the video fully covers the container */
  height: 130%;
  object-fit: cover;
}

/* Header Text Sections */
.registersection {
  height: 100%;
  background-color: var(--color-primary);
  color: var(--color-cream);
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px;
  box-sizing: border-box;
}

.registrationquadrantone {
  height: 50%;
  width: 70%;
  display: flex;
}

#alignleft {
  text-align: start;
}

#alignright {
  text-align: end;
}

#nomargin {
  margin: 0;
}

.registrationquadranttwo {
  height: 50%;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headertext3 {
  height: 50%;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.registrationquadrantthreeandfour {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.faqquestions {
  margin: 0 20px 40px 20px;
  text-align: end;
  align-items: center;
}

/* Header Image Section */
.faqsection {
  position: relative; /* Ensure positioning for the overlay */
  background-image: url("../images/subheader.png");
  background-size: cover;
  background-position: center;
  height: 10em;
  background-color: var(--color-primary);
  color: var(--color-cream);
  width: 100%;
  display: flex; /* Changed to flex for centering */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  padding: 20px; /* Added padding for better spacing */
  box-sizing: border-box; /* Ensure padding is included in width/height */
  font-size: 30px;
  text-align: center;
}

.faqsection::before {
  content: ""; /* Required for pseudo-element */
  position: absolute; /* Position it absolutely within the parent */
  top: 0; /* Cover the entire area */
  left: 0; /* Cover the entire area */
  right: 0; /* Cover the entire area */
  bottom: 0; /* Cover the entire area */
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay with 50% opacity */
  z-index: 1; /* Ensure it sits above the background image */
}

.faqsection > * {
  position: relative; /* Ensure child elements are above the overlay */
  z-index: 2; /* Ensure content is above the overlay */
}

.subsection {
  background-color: #da4527;
  display: flex; /* Added to enable flexbox */
  flex-direction: column; /* Stack items vertically */
  padding: 1em; /* Add padding for better spacing */
  text-align: center; /* Center text for better readability */
  height: 10em;
  color: #faf0ca;
  font-size: 20px;
}

.headerimage4 {
  height: 50%;
  width: 10%;
}

/* ========= Date Section ========= */
.datediv {
  text-align: center;
  height: 6em;
  background-color: var(--color-white);
  border-top: 4px solid var(--color-black);
  border-bottom: var(--border-standard);
  display: flex; /* Changed to flex for centering */
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
}

/* ========= Info List ========= */
.listDiv {
  height: 7em;
  background-color: var(--color-white);
  color: var(--color-black);
  border-top: 4px solid var(--color-black);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2em;
}

.listDiv:hover {
  color: var(--color-white);
  background-color: var(--color-black);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.listDivBottom {
  height: 7em;
  background-color: var(--color-white);
  border-top: 4px solid var(--color-black);
  border-bottom: var(--border-standard);
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Pushes inner divs to opposite sides */
  align-items: center; /* Vertically centers content */
  padding: 0 2em; /* Adds space on both sides */
}

.listDivBottom:hover {
  color: #ffffff;
  background-color: #000000;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.leftsidelistdiv {
  font-size: xx-large;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 4em;
}

.rightsidelistdiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 4em;
}

/* ========= Stickers ========= */
.sticker {
  position: absolute;
  top: 10em;
  left: 2%;
}

/* ========= Links & Buttons ========= */
.nodecoration {
  text-decoration: none;
  color: inherit;
}

.nodecorationunderline {
  color: var(--color-primary);
}

/* Button Base Styles */
.buttonblack,
.buttonwhite {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10em;
  height: 3.6em;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 6px;
  font-weight: bold;
  font-family: inherit;
  font-size: 15px;
  transition: color 0.5s;
  z-index: 1;
}

.buttonblack {
  --button-color: var(--color-black);
  border: 2px solid var(--button-color);
  color: var(--button-color);
}

.buttonwhite {
  --button-color: var(--color-cream);
  border: 2px solid var(--button-color);
  color: var(--button-color);
}

.buttonblack:hover,
.buttonwhite:hover {
  color: var(--button-color);
}

.buttonblack:hover {
  color: var(--color-cream);
}
.buttonwhite:hover {
  color: var(--color-black);
}

.buttonblack:before,
.buttonwhite:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: var(--button-color);
  height: 150px;
  width: 200px;
  border-radius: 50%;
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.buttonblack:hover:before,
.buttonwhite:hover:before {
  top: -30px;
  left: -30px;
}

/* ========= Guest Speaker Section ========= */

.guestcontainer {
  padding: 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);
  color: var(--color-cream);
  width: 100%;
  height: fit-content;
  background-size: cover; /* Ensure the background image covers the container */
}
.speakerdiv1,
.speakerdiv2,
.speakerdiv3,
.speakerdiv4,
.speakerdiv5 {
  position: relative; /* Ensure positioning for the overlay */
  background-image: url("../images/jeanne.png"); /* Replace with actual image path */
  background-size: 110%; /* Zoom out the image */
  background-position: center -7.5em; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  padding: 2%;
  height: 5em;
  font-size: 40%; /* Adjust this value as needed for smaller font size */
  letter-spacing: normal;
  display: flex; /* Ensure flexbox is enabled */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  text-align: center; /* Center text */
  width: 100vw; /* Change to viewport width to ensure full width */
  max-width: none; /* Remove any max-width restrictions */
  overflow: hidden; /* Hide overflow if content exceeds the container */
}

.speakerdiv1::before,
.speakerdiv2::before,
.speakerdiv3::before,
.speakerdiv4::before,
.speakerdiv5::before {
  content: ""; /* Required for pseudo-element */
  position: absolute; /* Position it absolutely within the parent */
  top: 0; /* Cover the entire area */
  left: 0; /* Cover the entire area */
  right: 0; /* Cover the entire area */
  bottom: 0; /* Cover the entire area */
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay with 50% opacity */
  z-index: 1; /* Ensure it sits above the background image */
}

.speakerdiv1 > *,
.speakerdiv2 > *,
.speakerdiv3 > *,
.speakerdiv4 > *,
.speakerdiv5 > * {
  position: relative; /* Ensure child elements are above the overlay */
  z-index: 2; /* Ensure content is above the overlay */
}

.speakerdiv2 {
  background-image: url("../images/abe.png"); /* Replace with actual image path */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-size: 105%; /* Zoom out the image */
  background-position: center -7.5em; /* Centers the image */
  padding: 2%;
  height: 5em;
  font-size: 40%; /* Adjust this value as needed for smaller font size */
  letter-spacing: normal;
  display: flex; /* Ensure flexbox is enabled */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  text-align: center; /* Center text */
  width: 100vw; /* Change to viewport width to ensure full width */
  max-width: none; /* Remove any max-width restrictions */
  overflow: hidden; /* Hide overflow if content exceeds the container */
}

.speakerdiv3 {
  background-image: url("../images/CHRIS.png"); /* Replace with actual image path */
  background-size: 105%; /* Zoom out the image */
  background-position: center -7.5em; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  padding: 2%;
  height: 5em;
  font-size: 40%; /* Adjust this value as needed for smaller font size */
  letter-spacing: normal;
  display: flex; /* Ensure flexbox is enabled */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  text-align: center; /* Center text */
  width: 100vw; /* Change to viewport width to ensure full width */
  max-width: none; /* Remove any max-width restrictions */
  overflow: hidden; /* Hide overflow if content exceeds the container */
}

.speakerdiv4 {
  background-image: url("../images/austin.png"); /* Replace with actual image path */
  background-size: 110%; /* Zoom out the image */
  background-position: center -8em; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  padding: 2%;
  height: 5em;
  font-size: 40%; /* Adjust this value as needed for smaller font size */
  letter-spacing: normal;
  display: flex; /* Ensure flexbox is enabled */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  text-align: center; /* Center text */
  width: 100vw; /* Change to viewport width to ensure full width */
  max-width: none; /* Remove any max-width restrictions */
  overflow: hidden; /* Hide overflow if content exceeds the container */
}

.speakerdiv5 {
  background-image: url("../images/worshipSec.png"); /* Replace with actual image path */
  background-size: 110%; /* Zoom out the image */
  background-position: center -10em; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  padding: 2%;
  height: 5em;
  font-size: 40%; /* Adjust this value as needed for smaller font size */
  letter-spacing: normal;
  display: flex; /* Ensure flexbox is enabled */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  text-align: center; /* Center text */
  width: 100vw; /* Change to viewport width to ensure full width */
  max-width: none; /* Remove any max-width restrictions */
  overflow: hidden; /* Hide overflow if content exceeds the container */
}

/* Add this CSS to your styles (HIAHomePage.css) */
.speakerdivcontent {
  display: none; /* Hide by default */
  font-family: var(--font-stack);
  font-weight: lighter;
  font-size: 40%;
  letter-spacing: -0.1em;
  text-transform: uppercase; /* Added to make font always capital */
  text-align: center;
}

.speakerdivcontent.show {
  display: block; /* Show when the 'show' class is added */
}

.speaker-container:hover .speakerdivcontent,
.speaker-container.active .speakerdivcontent {
  display: block; /* Show on hover or when active */
  background: #da4527;
  height: fit-content;
}

.speakername {
  margin-inline: 0.3em;
}

.speakername {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.speakername.fade-in {
  opacity: 1;
  transform: translateX(0);
}

/* ========= Merch Section ========= */
.merchsection {
  background-color: var(--color-primary);
  color: var(--color-cream);
  width: 100%;
  height: 30em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.merchsectionleft {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-black);
  box-sizing: border-box;
  margin: 0;
}

.merchsectionright {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ========= Footer Section ========= */
.footersection {
  flex-shrink: 0;
  padding: 3em 2em;
  background-color: var(--color-black);
  color: var(--color-cream);
  text-align: center;
}

.footerContent {
  max-width: 1200px;
  margin: 0 auto; /* Center the footerContent */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.footerLeft,
.footerCenter,
.footerRight {
  flex: 1;
  margin: 0 1em; /* Add margin to individual items for spacing */
}

.footerContent h3 {
  margin-bottom: 1em;
  font-size: 1.2em;
}

.footerContent p {
  margin: 0.5em 0;
  font-size: 0.9em;
  line-height: 1.4;
}

.socialLinks {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
}

.socialLinks a {
  color: var(--color-cream);
  text-decoration: none;
  font-size: 0.9em;
}

.socialLinks a:hover {
  color: var(--color-primary);
  transition: color 0.3s ease;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .footersection {
    padding: 0%;
    height: 35em; /* Ensure there's a minimum height for the footer */
  }

  .footerContent {
    flex-direction: column;
    gap: 2.5em;
  }

  .footerLeft,
  .footerCenter,
  .footerRight {
    text-align: center;
    width: 100%;
  }

  .footerContent h3 {
    font-size: 1.1em;
    margin-bottom: 0.8em;
  }

  .footerContent p {
    font-size: 0.85em;
  }

  .socialLinks {
    align-items: center;
    gap: 0.6em;
  }

  /* Adjustments for smaller devices */
  .header {
    height: 15em; /* Reduced height for smaller screens */
  }

  .videocontainer {
    height: 15em; /* Adjusted height for mobile */
  }

  .datediv {
    padding: 1em; /* Added padding for better spacing */
  }

  .datediv p {
    font-size: 80%; /* Adjusted font size for better readability */
  }

  .regularfontguest {
    font-size: 300%; /* Adjusted font size for guest speakers */
  }

  .listDiv,
  .listDivBottom {
    height: 5em; /* Adjusted height for list items */
    padding: 0 1em; /* Added padding for better spacing */
  }

  .leftsidelistdiv {
    margin-left: 1em; /* Adjusted margin for better alignment */
    font-size: large; /* Adjusted font size */
  }

  .rightsidelistdiv {
    margin-right: 1em; /* Adjusted margin for better alignment */
    font-size: small; /* Adjusted font size */
  }

  /* Guest Speakers */
  .speakerdiv1,
  .speakerdiv2,
  .speakerdiv3,
  .speakerdiv4,
  .speakerdiv5 {
    height: 6em; /* Increased height for better visibility */
    font-size: 30%; /* Adjusted font size for better readability */
  }

  .speakerdivcontent {
    padding: 1em; /* Adjusted padding for better spacing */
    height: auto; /* Allow height to adjust based on content */
  }
}

/* Small Mobile Devices */
@media screen and (max-width: 480px) {
  .footersection {
    padding: 1.5em 0.8em;
  }

  .footerContent {
    gap: 2em;
  }

  .footerContent h3 {
    font-size: 1em;
  }

  .footerContent p {
    font-size: 0.8em;
  }

  .regularfontguest {
    width: 100%;
    font-size: 250%; /* Further reduced font size for very small devices */
  }

  .datediv p {
    font-size: 60%; /* Further reduced font size for very small devices */
  }

  .buttonblack,
  .buttonwhite {
    width: 7em; /* Reduced button width */
    height: 2.5em; /* Reduced button height */
    font-size: 12px; /* Reduced font size */
  }

  .datediv {
    text-align: center;
    height: 3em;
    background-color: var(--color-white);
    border-top: 4px solid var(--color-black);
    border-bottom: var(--border-standard);
    display: flex; /* Changed to flex for centering */
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
  }
}

/* Update the app container and content wrapper styles */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Mobile Styles for FAQ Section */
@media screen and (max-width: 768px) {
  .faqsection {
    flex-direction: column; /* Stack items vertically */
    padding: 2em 1em 1em 1em; /* Add padding for better spacing */
    text-align: center; /* Center text for better readability */
    height: 11em;
    font-size: 10px;
    text-align: center;
  }

  .subsection {
    flex-direction: column; /* Stack items vertically */
    padding: 1em; /* Add padding for better spacing */
    text-align: center; /* Center text for better readability */
    height: 14em;
    font-size: 10px;
    text-align: center;
  }
}
