.faqpage {
  background-color: #195083;
  min-height: auto;
  overflow-y: auto;
  padding: 20px 0;
  box-sizing: border-box;
  position: relative;
  width: 100%;
}

.faq-list {
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
  height: auto;
}

.faq-item {
  margin-bottom: 10px;
}

.faq-question {
  width: 100%;
  padding: 12px;
  background-color: #f9f9f9;
  border: none;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.faq-question:hover {
  background-color: #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.faq-question.active {
  background-color: #e0e0e0;
}

.faq-answer {
  padding: 15px;
  background-color: #ffffff;
  border: none;
  font-size: 0.95rem;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.arrow {
  margin-right: 10px;
}

/* font */
.regularfont {
  font-family: var(--font-stack);
  font-weight: bold;
  font-size: 120%;
}

.innerfont {
  font-family: var(--font-stack);
  font-weight: bold;
  font-size: 90%;
}

@media screen and (max-width: 768px) {
  .faq-list {
    padding: 15px;
  }

  .faq-question {
    padding: 12px;
    font-size: 1rem;
  }

  .faq-answer {
    padding: 12px;
  }

  .regularfont {
    font-size: 100%;
  }

  .innerfont {
    font-size: 85%;
  }
}

@media screen and (max-width: 480px) {
  .faq-list {
    padding: 10px;
  }

  .faq-question {
    padding: 10px;
    font-size: 0.95rem;
  }

  .faq-answer {
    padding: 10px;
  }
}

footer {
  margin-top: 0;
  padding-top: 0;
}
