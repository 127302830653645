.merchPage  {
    background-color: #da4527;
}

.merchPage img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; /* Set the desired width */
    margin: 1em auto; /* Center the item horizontally */
}
