.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}



/* ========= Navigation ========= */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-black);
  color: var(--color-white);
  height: 5em;
  font-size: larger;
  padding: 0%;
}

.navbaroptions {
  font-family: var(--font-stack);
  font-weight: bold;
  font-size: 75%;
  text-align: justify;
  margin-left: auto;
}

.navbaroptions{
  margin-right: auto;
}

.navbarlink {
  text-decoration: none;
  color: inherit;
  margin-right: 1em;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  /* Navigation */
  .navbar {
    height: 4em;
    padding: 0 1em; /* Adjust padding for mobile */
    margin: 0; /* Remove margin */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbaroptions.navbarlogo {
    margin-right: 1em;
  }


}
